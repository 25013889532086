import classnames from "classnames";
import { Component } from "react";
import ReactSidebar, { SidebarProps } from "react-sidebar";

import { ReactComponent as LogoHorizontal } from "nvent-web/assets/svg/logo-horizontal.svg";
import { ReactComponent as CloseIcon } from "nvent-web/assets/svg/side-menu/close.svg";

import style from "./SidebarLayout.module.scss";

export type SidebarLayoutProps = Pick<SidebarProps, "docked" | "onSetOpen" | "open" | "sidebar" | "transitions">;

export class SidebarLayout extends Component<SidebarLayoutProps> {
  render() {
    const { sidebar, children, docked, ...forwardedProps } = this.props;

    return (
      <ReactSidebar
        sidebar={
          <>
            <div className={style.sidebarHeader}>
              <a href="https://www.nvent.com/raychem" target="_blank" rel="noopener noreferrer">
                <LogoHorizontal width={250} />
              </a>
              <button onClick={this.closeSidebar} className={style.sidebarCloseButton}>
                <CloseIcon />
              </button>
            </div>
            <div className={style.sidebarContent}>{sidebar}</div>
          </>
        }
        rootClassName={classnames(style.root, { [style.docked]: docked })}
        overlayClassName={style.overlay}
        contentClassName={style.content}
        sidebarClassName={style.sidebar}
        touchHandleWidth={50}
        docked={docked}
        {...forwardedProps}
      >
        <div className={style.contentContainer}>{children}</div>
      </ReactSidebar>
    );
  }

  private closeSidebar = () => {
    const { onSetOpen } = this.props;

    if (onSetOpen) {
      onSetOpen(false);
    }
  };
}
